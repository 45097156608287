<template>
  <div class="learningVideo">
    <div class="noStudyVideo" v-if="isHaveVideo">
      <div class="studyTitle">学习</div>
      <div class="studyContent">
        <div class="contentImage"></div>
        <div class="contentTitle">培训材料准备中，敬请期待！</div>
      </div>
    </div>
    <div class="studyVideo" v-else>
      <div class="studyTitle">学习</div>

      <div class="videoTraining">
        视频培训
        <div class="link"></div>
      </div>
      <div
        class="relatedVideo"
        v-for="(item, index) in videoList"
        :key="index"
        @click="onClick(item)"
      >
        <div class="title"><i class="videoIcon"></i>{{ item.groupName }}</div>
        <div class="videoBox">
          <div class="headPortrait"></div>
          <div class="name">{{ item.author }}</div>
          <div class="videoTitle">{{ item.title }}</div>
          <div class="video">
            <img
              :src="
                item.url +
                '?x-oss-process=video/snapshot,t_1000,f_jpg,w_690,h_390,m_fast'
              "
              style="width: 100%; height: 100%"
            />
          </div>
          <div class="videoIntroduce">
            {{ item.description }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { studyVideoList } from "../api/home";
import { Dialog } from "vant";

export default {
  components: {
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      isHaveVideo: false, //有无视频显示
      playerId: "aliplayer_" + Math.random().toString(36).substr(2),
      scriptTagStatus: 0,
      isReload: false,
      instance: null,
      videoList: [],
    };
  },
  created() {},
  mounted() {
    this.getStudyVideoList();
  },
  methods: {
    getStudyVideoList() {
      let params = {
        pageNo: 1,
        userId: this.$route.query.userId,
      };
      studyVideoList(params).then((res) => {
        if (res.result.length > 0) {
          this.videoList = res.result;
        } else {
          this.isHaveVideo = true;
        }
      });
    },
    onClick(data) {
      this.$router.push({ name: "videoPlayer", params: { data: data } });
    },
  },
};
</script>

<style lang="less" scoped>
@import "https://g.alicdn.com/de/prismplayer/2.9.0/skins/default/aliplayer-min.css";
.noStudyVideo {
  display: block;
  background: #ccc;

  .studyTitle {
    width: 750px;
    height: 79px;
    background: #ffffff;
    text-align: center;
    line-height: 79px;
    font-size: 34px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #111111;
  }

  .studyContent {
    position: relative;

    .contentImage {
      position: absolute;
      width: 288px;
      height: 306px;
      background: url("../assets/images/learn/studyVideo.png") no-repeat;
      background-size: 100%;
      left: 50%;
      top: 308px;
      margin-left: -144px;
    }

    .contentTitle {
      position: absolute;
      height: 29px;
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #999999;
      top: 647px;
      left: 203px;
    }
  }
}

.studyVideo {
  display: block;
  background: #ccc;

  .studyTitle {
    width: 750px;
    height: 79px;
    background: #ffffff;
    text-align: center;
    line-height: 79px;
    font-size: 34px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #111111;
  }

  .videoTraining {
    position: relative;
    display: flex;
    width: 750px;
    height: 97px;
    background: #ffffff;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.06);
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ff7000;
    line-height: 97px;
    padding-left: 31px;
    box-sizing: border-box;

    .link {
      position: absolute;
      width: 50px;
      height: 4px;
      background: #ff7000;
      border-radius: 2px;
      bottom: 9px;
      margin-left: 29px;
    }
  }

  .relatedVideo {
    width: 750px;
    background: #ffffff;
    margin-top: 20px;
    text-align: left;

    .title {
      height: 25px;
      font-size: 26px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 25px;
      padding-top: 50px;

      .videoIcon {
        display: inline-block;
        width: 30px;
        height: 24px;
        margin-left: 30px;
        background: url("../assets/images/learn/videoIcon.png") no-repeat;
        background-size: 100%;
        margin-right: 7px;
        vertical-align: bottom;
      }
    }

    .videoBox {
      margin-top: 40px;
      margin-left: 30px;
      width: 690px;
      border-bottom: 1px solid #edeeef;

      .headPortrait {
        display: inline-block;
        width: 60px;
        height: 60px;
        vertical-align: middle;
        border-radius: 50%;
        background: url("../assets/images/learn/headPortrait.png") no-repeat;
        background-size: 100%;
      }

      .name {
        display: inline-block;
        height: 27px;
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #161718;
        line-height: 30px;
        margin-left: 10px;
      }

      .videoTitle {
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
        margin-top: 25px;
      }

      .video {
        position: relative;
        width: 690px;
        height: 390px;
        background: #000000;
        border-radius: 10px;
        margin-top: 20px;

        .suspended {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 80px;
          height: 80px;
          margin-left: -40px;
          margin-top: -40px;
          background: url("../assets/images/learn/suspended.png") no-repeat;
          background-size: 100%;
        }

        .fullScreen {
          position: absolute;
          width: 26px;
          height: 26px;
          right: 20px;
          bottom: 18px;
          background: url("../assets/images/learn/fullScreen.png") no-repeat;
          background-size: 100%;
        }

        .videoTime {
          position: absolute;
          right: 62px;
          bottom: 13px;
          background: #000000;
          opacity: 0.5;
          border-radius: 19px;
          color: #fff;
          font-size: 20px;
          text-align: center;
          line-height: 37px;
          padding: 0 14px;

          .state {
            color: #efc726;
            margin-left: 10px;
          }
        }
      }

      .videoIntroduce {
        margin-top: 20px;
        width: 682px;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #808080;
        line-height: 30px;
        padding-bottom: 30px;
      }
    }

    .videoBox:last-child {
      border-bottom: none;
    }
  }

  .relatedVideo:nth-child(3) {
    margin-top: 0;
  }
}
</style>